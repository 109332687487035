import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elOnboardinginteractivemapCopy2 from './images/Comp4_elOnboardinginteractivemapCopy2_516392.png';

export default class Comp4 extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  render() {
    const style_elInteractivedashboard = {
      fontSize: 16.0,
      color: '#ff9300',
      textAlign: 'left',
     };
    const style_elInteractivedashboardtext = {
      fontSize: 16.0,
      color: '#919191',
      textAlign: 'left',
     };
    const style_elOnboardinginteractivemapCopy2 = {
      height: 'auto',
     };
    
    return (
      <div className="Comp4">
        <div className="layoutFlow">
          <div className="elInteractivedashboard">
            <div className="font-ralewayBold" style={style_elInteractivedashboard}>
              <div>{this.context.locStrings.comp4_interactivedashboard_758210}</div>
            </div>
          </div>
          
          <div className="elInteractivedashboardtext">
            <div className="font-ralewayRegular" style={style_elInteractivedashboardtext}>
              <div>{this.context.locStrings.comp4_interactivedashboardtext_592549}</div>
            </div>
          </div>
          
          <div className="elOnboardinginteractivemapCopy2">
            <img style={style_elOnboardinginteractivemapCopy2} src={img_elOnboardinginteractivemapCopy2} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
}
