import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import firebase from 'firebase/app';
import 'firebase/firestore';
import img_elBgphoto from './images/AccountSetupScreen_state0_elBgphoto389432_389432.jpg';
import img_elProgressBar3 from './images/LastquestionScreen_elProgressBar3_910393.png';
import img_elClose from './images/AccountSetupScreen_state0_elClose538058_538058.png';

// UI framework component imports
import Textarea from 'muicss/lib/react/textarea';
import Button from 'muicss/lib/react/button';

export default class LastquestionScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
      elValidator_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  
  textAreaChanged_elTextareaforcare = (event) => {
    this.setState({textareaforcare: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_careAbout", event.target.value);
  }
  
  getValue_elTextareaforcare = () => {
    return this.state.textareaforcare || '';
  }
  
  
  textAreaChanged_elProfession = (event) => {
    this.setState({profession: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_profession", event.target.value);
  }
  
  getValue_elProfession = () => {
    return this.state.profession || '';
  }
  
  
  onClick_elButton = async () => {
    // Perform action 'Validate' on element 'validator'
    const items = [
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_careAbout'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_careAbout'] : '').toString().length > 0, name: 'ds_careAbout', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_careAbout'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_profession'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_profession'] : '').toString().length > 0, name: 'ds_profession', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_profession'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '').toString().length > 0, name: 'ds_SlotAge', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '').toString().length > 0, name: 'ds_SlotGender', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '').toString().length > 0, name: 'ds_SlotGender', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotGender'] : '') },
      { result: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') != null && (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '').toString().length > 0, name: 'ds_SlotAge', value: (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotAge'] : '') },
    ];
    
    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log("Validator 'validator' failed: item '" + item.name + "' is not valid, value: '" + item.value + "'");
        }
        break;
      }
    };
    this.setState({elValidator_visible: !valid});
    
    if ( !valid) {
      return;
    }
  
    var db = firebase.firestore();
    var uid = localStorage.getItem('ds_SlotUserId')
    var careabout = localStorage.getItem('ds_careAbout')
    var profession = localStorage.getItem('ds_profession')
    //var customer = this.props.stripeId;
        
       
    	
    let setDoc2 = await db.collection(`users`).doc(`${uid}`).update({
              careabout: careabout,
              profession: profession,
              })
            .then(setDoc2 => {
            }) 
  
    // Go to screen 'Feed'
    this.context.appActions.goToScreen('feed', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elClose = async () => {
    // Go to screen 'geoaddress'
    this.context.appActions.goToScreen('geoaddress', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBgphoto = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_homebackground'] : ''))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elBackground = {
      width: '100%',
      height: '100%',
      borderRadius: '20.0px',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
      borderRadius: '20.0px',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elProgressBar3 = {
      backgroundImage: 'url('+img_elProgressBar3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elCareabout = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elTextareaforcare = {
      display: 'block',
      pointerEvents: 'auto',
     };
    const style_elProfessiontext = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elProfession = {
      display: 'block',
      pointerEvents: 'auto',
     };
    const style_elValidator = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const elValidator = this.state.elValidator_visible ? (
      <div className="elValidator">
        <div className="baseFont" style={style_elValidator}>
          <div>{this.context.locStrings.lastquestion_validator_280487}</div>
        </div>
      </div>
      
     ) : null;
    
    const style_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elClose = {
      backgroundImage: 'url('+img_elClose+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen LastquestionScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBgphoto" style={style_elBgphoto} />
          <div className="elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.context.locStrings.accountsetup_text_915386}</div>
            </div>
          </div>
          
          <div className="elProgressBar3">
            <div style={style_elProgressBar3} />
          </div>
          
          <div className="elText3">
            <div className="baseFont" style={style_elText3}>
              <div>{this.context.locStrings.lastquestion_text3_915177}</div>
            </div>
          </div>
          
          <div className="elCareabout">
            <div className="headlineFont" style={style_elCareabout}>
              <div>{this.context.locStrings.lastquestion_text_859271}</div>
            </div>
          </div>
          
          <div className="elTextareaforcare">
            <Textarea className="baseFont" style={style_elTextareaforcare}  floatingLabel={true} label={this.context.locStrings.lastquestion_textarea_439406} onChange={this.textAreaChanged_elTextareaforcare} value={this.getValue_elTextareaforcare()}  />
          </div>
          
          <div className="elProfessiontext">
            <div className="headlineFont" style={style_elProfessiontext}>
              <div>{this.context.locStrings.lastquestion_textcopy_695044}</div>
            </div>
          </div>
          
          <div className="elProfession">
            <Textarea className="baseFont" style={style_elProfession}  floatingLabel={true} label={this.context.locStrings.lastquestion_textareacopy_355132} onChange={this.textAreaChanged_elProfession} value={this.getValue_elProfession()}  />
          </div>
          
          { elValidator }
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.lastquestion_button_311209}
            </Button>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elClose" style={style_elClose} onClick={this.onClick_elClose}  />
          </div>
        </div>
      </div>
    )
  }
  
}
