import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elNeighsomerville from './images/HomeScreen_elNeighsomerville_797036994.jpg';
import img_elLogomark from './images/HomeScreen_elLogomark_667227.png';
import img_elLogofffffff from './images/HomeScreen_elLogofffffff_89302.png';
import Footer from './Footer';
import OpenlanguageSelector from './OpenlanguageSelector';
import Selector from './Selector';

export default class HomeScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    
  // fetch geo country 
         function geo() {
          var https = require('https');
          https.get('https://ipapi.co/json/', function(resp){
              var body = ''
              resp.on('data', function(data){
                  body += data;
              });

              resp.on('end', function(){
                  var loc = JSON.parse(body);
                  console.log('Ip loc' , loc);

                  var Ipcountry_name = loc.country_name
                  var ptbr = 'ptbr'
                  console.log('Ip country_name', Ipcountry_name)
                  localStorage.setItem("ds_SlotCountry", Ipcountry_name);

                  if (Ipcountry_name == 'Brazil'){
                    localStorage.setItem("ds_activeLang", ptbr);
                  }
              });
          });
    } 
   geo();
   //end ini ipapi
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    const style_elNeighsomerville = {
      backgroundImage: 'url('+img_elNeighsomerville+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elLogomark = {
      backgroundImage: 'url('+img_elLogomark+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elLogofffffff = {
      backgroundImage: 'url('+img_elLogofffffff+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTextCopy = {
      color: '#feffff',
      textAlign: 'center',
     };
    const style_elText = {
      fontSize: 20.1,
      color: '#feffff',
      textAlign: 'center',
     };
    
    const style_elRect = {
      background: 'rgba(246, 246, 246, 1.000)',
      display: 'none',
     };
    const elOpenlanguageSelector = ((val) => { return val === "true" || val == true || val == 1 })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotLangSelector'] : '')) ? (
      <div className="hasNestedComps elOpenlanguageSelector">
        <OpenlanguageSelector ref={(el)=> this._elOpenlanguageSelector = el} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen HomeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="containerMinHeight elNeighsomerville" style={style_elNeighsomerville} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elLogomark">
            <div style={style_elLogomark} />
          </div>
          
          <div className="elLogofffffff">
            <div style={style_elLogofffffff} />
          </div>
          
          <div className="elTextCopy">
            <div className="headlineFont" style={style_elTextCopy}>
              <div>{this.context.locStrings.home_textcopy_2110137429}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="font-ralewayRegular" style={style_elText}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.home_text_231455704.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="hasNestedComps elFooter">
            <div>
              <Footer ref={(el)=> this._elFooter = el} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elRect" style={style_elRect} />
            { elOpenlanguageSelector }
            <div className="hasNestedComps elSelector">
              <Selector ref={(el)=> this._elSelector = el} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
