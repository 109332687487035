import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elOnboardinginteractivemap from './images/Comp1_elOnboardinginteractivemap_564603.png';

export default class Comp1 extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  render() {
    const style_elInteractivemap = {
      fontSize: 16.0,
      color: '#ff9200',
      textAlign: 'left',
     };
    const style_elInteractivestreetext = {
      fontSize: 16.0,
      color: '#919191',
      textAlign: 'left',
     };
    const style_elOnboardinginteractivemap = {
      height: 'auto',
     };
    
    return (
      <div className="Comp1">
        <div className="layoutFlow">
          <div className="elInteractivemap">
            <div className="font-ralewayBold" style={style_elInteractivemap}>
              <div>{this.context.locStrings.comp1_interactivemap_859654}</div>
            </div>
          </div>
          
          <div className="elInteractivestreetext">
            <div className="font-ralewayRegular" style={style_elInteractivestreetext}>
              <div>{this.context.locStrings.comp1_interactivestreetext_193613}</div>
            </div>
          </div>
          
          <div className="elOnboardinginteractivemap">
            <img style={style_elOnboardinginteractivemap} src={img_elOnboardinginteractivemap} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
}
