import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_state0_elUsernophotosetIcon from './images/Logout_state0_elUsernophotosetIcon_1976519767.jpg';
import firebase from 'firebase';
import firestore from 'firebase/firestore';
import img_state1_elUsernophotosetIcon from './images/Logout_state0_elUsernophotosetIcon_1976519767.jpg';

export default class Logout extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  
  onClick_state0_elText = async () => {
    this.context.appActions.updateDataSlot('ds_userIsSubscribed', "");
    this.context.appActions.updateDataSlot('ds_SlotUserName', "");
    this.context.appActions.updateDataSlot('ds_SlotPhoneNumber', "");
    this.context.appActions.updateDataSlot('ds_SlotUserEmail', "");
    this.context.appActions.updateDataSlot('ds_SlotUserId', "");
    this.context.appActions.updateDataSlot('ds_SlotUserPhoto', "");
  
    // 'Sign out (lock gate)' action.
    this.clearSavedLogin();
    sessionStorage.setItem('loginStatus_start', 'inactive');
    this.context.appActions.goToScreen('start', this.context.baseScreenId);
  
  }
  
  
  onClick_state0_elUsernophotosetIcon = async () => {
    this.context.appActions.updateDataSlot('ds_SlotUserName', "");
    this.context.appActions.updateDataSlot('ds_SlotUserEmail', "");
    this.context.appActions.updateDataSlot('ds_SlotUserPhoto', "");
    this.context.appActions.updateDataSlot('ds_SlotPhoneNumber', "");
    this.context.appActions.updateDataSlot('ds_userIsSubscribed', "");
    this.context.appActions.updateDataSlot('ds_SlotUserId', "");
  
    // 'Sign out (lock gate)' action.
    this.clearSavedLogin();
    sessionStorage.setItem('loginStatus_start', 'inactive');
    this.context.appActions.goToScreen('start', this.context.baseScreenId);
  
  }
  
  
  clearSavedLogin() {
    // Logout
    firebase.auth().signOut();
  }
  
  
  renderState0() {
    const style_state0_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_usernophotosetIcon = (input) => {
      //var userid  = localStorage.getItem('ds_SlotUserId')
      //console.log('userid', userid)
      return input;
    }
    const style_state0_elUsernophotosetIcon = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(transformPropValue_usernophotosetIcon((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_SlotUserPhoto'] : '')))+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '14.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Logout">
        <div className="layoutFlow">
          <div className="state0_elText">
            <div className="baseFont" style={style_state0_elText} onClick={this.onClick_state0_elText} >
              <div>{this.context.locStrings.logout_text_685915}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="state0_elUsernophotosetIcon" style={style_state0_elUsernophotosetIcon} onClick={this.onClick_state0_elUsernophotosetIcon}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  
  onClick_state1_elText = async () => {
  
  }
  
  
  onClick_state1_elUsernophotosetIcon = async () => {
  
  }
  
  
  renderState1() {
    const style_state1_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state1_elUsernophotosetIcon = {
      backgroundImage: 'url('+img_state1_elUsernophotosetIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '20.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Logout">
        <div className="layoutFlow">
          <div className="state1_elText">
            <div className="baseFont" style={style_state1_elText} onClick={this.onClick_state1_elText} >
              <div>{this.context.locStrings.logout_text_1723526480}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="state1_elUsernophotosetIcon" style={style_state1_elUsernophotosetIcon} onClick={this.onClick_state1_elUsernophotosetIcon}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
